import styled from "styled-components";
import iiButton from "../../../../src/images/internet-identity-button.png";
import whoamiResult from "../../../../src/images/whoami-result.png";
import { BlogImage } from "../../../../src/components/BlogComponents";
import * as React from 'react';
export default {
  styled,
  iiButton,
  whoamiResult,
  BlogImage,
  React
};