import Breonna from "../../../../src/images/Breonna_Taylor.jpeg";
import George from "../../../../src/images/George_Floyd.jpg";
import after_curfew from "../../../../src/images/after_curfew.jpeg";
import median_wealth from "../../../../src/images/median_wealth.jpeg";
import { FlexImages, BlogImage } from "../../../../src/components/BlogComponents";
import Helmet from "react-helmet";
import * as React from 'react';
export default {
  Breonna,
  George,
  after_curfew,
  median_wealth,
  FlexImages,
  BlogImage,
  Helmet,
  React
};