import gatsbyInit from "../../../../src/images/gatsby-init.png";
import contactCard from "../../../../src/images/contact-card.png";
import { BlogImage } from "../../../../src/components/BlogComponents";
import ContactStartingIndex from "../../../../src/documents/contact-starting-index.mdx";
import ContactMainMo from "../../../../src/documents/contact-main.mdx";
import GatsbyNode from "../../../../src/documents/gatsby-node.mdx";
import * as React from 'react';
export default {
  gatsbyInit,
  contactCard,
  BlogImage,
  ContactStartingIndex,
  ContactMainMo,
  GatsbyNode,
  React
};