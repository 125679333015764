import { TitleSection, BlogImage, Description } from "../../../../src/components/BlogComponents";
import display_parts from "../../../../src/images/display_parts.jpg";
import assembled from "../../../../src/images/display_assembled.jpg";
import * as React from 'react';
export default {
  TitleSection,
  BlogImage,
  Description,
  display_parts,
  assembled,
  React
};