import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Block } from "mdx-blocks";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Block {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre {...{
      "className": "language-clike"
    }}><code parentName="pre" {...{
        "className": "language-clike"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// Main.mo`}</span>{`
import HM `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/HashMap"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
import Text `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Text"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
import Error `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Error"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
import Iter `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Iter"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`


actor `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  stable var entries `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  let store`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` HM`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`HashMap`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` Text`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` HM`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`fromIter`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`entries`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`vals`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`16`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`equal`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`hash`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`/// returns null if there was no previous value, else returns previous value`}</span>{`
  public shared func `}<span parentName="code" {...{
          "className": "token function"
        }}>{`set`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`k`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`v`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` async `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{`Text `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`k `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`==`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`throw`}</span>{` Error`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`reject`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Empty string is not a valid key"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` store`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`replace`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`k`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` v`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  public query func `}<span parentName="code" {...{
          "className": "token function"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`k`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`Text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` async `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{`Text `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` store`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`k`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  system func `}<span parentName="code" {...{
          "className": "token function"
        }}>{`preupgrade`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    entries `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` Iter`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`toArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`store`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`entries`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  system func `}<span parentName="code" {...{
          "className": "token function"
        }}>{`postupgrade`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    entries `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      