import { BlogImage } from "../../../../src/components/BlogComponents";
import selectSource from "../../../../src/images/first_canister/select-source.png";
import addController from "../../../../src/images/first_canister/add-controller.png";
import canisterCreated from "../../../../src/images/first_canister/canister-created.png";
import canisterView from "../../../../src/images/first_canister/canister-view.png";
import confirmController from "../../../../src/images/first_canister/confirm-controller.png";
import createNewCanister from "../../../../src/images/first_canister/create-new-canister.png";
import customizeExampleSite from "../../../../src/images/first_canister/customize-example-site.png";
import deployExampleSite from "../../../../src/images/first_canister/deploy-example-site.png";
import enterAmount from "../../../../src/images/first_canister/enter-amount.png";
import getPrincipal from "../../../../src/images/first_canister/get-principal.png";
import liveSite from "../../../../src/images/first_canister/live-site.png";
import nnsAccounts from "../../../../src/images/first_canister/nns-accounts.png";
import reviewCanisterCreation from "../../../../src/images/first_canister/review-canister-creation.png";
import * as React from 'react';
export default {
  BlogImage,
  selectSource,
  addController,
  canisterCreated,
  canisterView,
  confirmController,
  createNewCanister,
  customizeExampleSite,
  deployExampleSite,
  enterAmount,
  getPrincipal,
  liveSite,
  nnsAccounts,
  reviewCanisterCreation,
  React
};