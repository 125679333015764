import Diff from "../../../../src/components/Diff";
import PrismExample from "../../../../src/components/PrismExample";
import vcImg from "../../../../src/images/vscode-version-control.png";
import { BlogImage, Description } from "../../../../src/components/BlogComponents";
import * as React from 'react';
export default {
  Diff,
  PrismExample,
  vcImg,
  BlogImage,
  Description,
  React
};