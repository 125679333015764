import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Block } from "mdx-blocks";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Block {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre {...{
      "className": "language-jsx"
    }}><code parentName="pre" {...{
        "className": "language-jsx"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// index.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="span" {...{
            "className": "token keyword module"
          }}>{`as`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`React`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"react"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`styled`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"styled-components"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`vCard`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"vcf"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// styles`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Main`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styled`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`main`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token css language-css"
          }}>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`color`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"#232129"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`96`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`font-family`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"-apple-system, Roboto, sans-serif, serif"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` fit-content`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`fieldset`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  label`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` flex`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`flex-direction`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` column`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`280`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` fit-content`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`ProfilePicture`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styled`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`picture`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token css language-css"
          }}>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` flex`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`256`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`100`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`%`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DataList`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styled`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`dl`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token css language-css"
          }}>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` grid`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`grid-template-columns`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` auto auto`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`dt`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  dd`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`/* width: fit-content; */`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` inline-flex`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span>{` solid `}<span parentName="span" {...{
              "className": "token color"
            }}>{`black`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`4`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`padding-right`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`16`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`picture`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  image`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`max-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`75`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`ContactCard`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` card `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span>{`card `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`||`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span>{`card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`data`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`section`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`DataList`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`Object`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`entries`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`data`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`map`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` value`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`]`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`_field`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` _data`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`_field`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"photo"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
              `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`React.Fragment`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`key`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token property-access"
            }}>{`_field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`dt`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`_field`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`dt`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`dd`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                  `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`ProfilePicture`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`img`}</span>{`
                      `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` maxWidth`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"75px"`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
                      `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`src`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`atob`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token property-access"
            }}>{`_data`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
                      `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`alt`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`profile`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
                    `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                  `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`ProfilePicture`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`dd`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
              `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`React.Fragment`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
              `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`dt`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`_field`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`dt`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
                `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`dd`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`_data`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`dd`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
              `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`DataList`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`a`}</span>{`
        `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`href`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`data:text/plain;charset=utf-8,`}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`encodeURIComponent`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`
          card`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="span" {...{
                  "className": "token method function property-access"
                }}>{`toString`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
        `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`download`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`contact.vcf`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
      `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        Download VCF
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`section`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// markup`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`IndexPage`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`image`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setImage`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`React`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setCard`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`React`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`actor`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setActor`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`React`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`handleSubmit`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`e`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    e`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`preventDefault`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` card `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`vCard`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` inputs `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` e`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`target`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`querySelectorAll`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"input"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` email `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` e`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`target`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`querySelector`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'input[name="email"]'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`value`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    inputs`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`forEach`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`input`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`name`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"photo"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`name`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"n"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Take full input and format for vcf`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` names `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`value`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`split`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`" "`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` arr `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Array`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`5`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

        names`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`reverse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`forEach`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`name`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` idx`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          arr`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`idx`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` name`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

        card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"fn"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`value`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`name`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` arr`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`join`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`";"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`name`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` input`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`value`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    card`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"photo"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`btoa`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`image`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` mediatype`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"image/gif"`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`false`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`handleUpload`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`e`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` file `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` e`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`target`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`files`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` reader `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`FileReader`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    reader`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
      `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"load"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// convert image file to base64 string`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setImage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`reader`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`result`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`false`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`file`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      reader`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`readAsDataURL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`file`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`getCard`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`e`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    e`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`preventDefault`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` email `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` e`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`target`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`querySelector`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'input[name="emailsearch"]'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`value`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`false`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Main`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Contact Book`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Internet Computer Address Book`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`section`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Look up a contact by email`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`form`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`onSubmit`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`getCard`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`emailsearch`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`email`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`emailsearch`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`id`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`emailsearch`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`submit`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Search`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`form`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`section`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token comment"
        }}>{`/* Card Display */`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`ContactCard`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`card`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`card`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`

      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`form`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`onSubmit`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`handleSubmit`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Add a Contact`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`fieldset`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Personal Information`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Full Name
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`autoComplete`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`name`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`org`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Organziation
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`org`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`autoComplete`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`organization`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Title
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`autoComplete`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`organization-title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`fieldset`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`fieldset`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Profile photo`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`photo`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Upload an image
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{`
              `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`file`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
              `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`id`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`img`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
              `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`photo`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
              `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`accept`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`image/*`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
              `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`onChange`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`handleUpload`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
            `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`image `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
            `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`ProfilePicture`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
              `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`src`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`image`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`alt`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`user-uploaded profile image`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`ProfilePicture`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`fieldset`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`fieldset`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Contact`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`tel`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Phone number
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`tel`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`adr`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Address
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`adr`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`autoComplete`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`on`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`htmlFor`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`email`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
            Email
            `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`required`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`email`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`email`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`autoComplete`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`email`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
          `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`fieldset`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`submit`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Submit Contact`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`form`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Main`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`default`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`IndexPage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      